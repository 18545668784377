export interface PointerCoordinates {
  readonly clientX: number;
  readonly clientY: number;
  readonly pageX: number;
  readonly pageY: number;
  readonly screenX: number;
  readonly screenY: number;
}

export interface PointerLikeEvent {
  touches?: {
    [index: number]: PointerCoordinates;
  };
  clientX?: number;
  clientY?: number;
  pageX?: number;
  pageY?: number;
  screenX?: number;
  screenY?: number;
}

/**
 * Extracts client, screen, and page coordinates from the passed mouse or
 * touch-like event.
 * @param e The event to extract coordinates from.
 */
export const getEventPointerCoordinates = <t extends="" PointerLikeEvent="">(
  e: T,
): PointerCoordinates => {
  const firstTouch = e.touches && e.touches[0];
  const {
    clientX = 0,
    clientY = 0,
    pageX = 0,
    pageY = 0,
    screenX = 0,
    screenY = 0,
  } = firstTouch || e;
  return { clientX, clientY, pageX, pageY, screenX, screenY };
};
</t>