import { Theme, bem, cx } from '@mint/core';
import { FormControlState } from '../../types';
import { useMemo } from 'react';

export const useFormControlClassNames = <
  TProps extends Partial<formcontrolstate> & { theme?: Theme }
>(
  className: string,
  props: TProps,
): string =>
  useMemo((): string => {
    const b = bem(className);
    return cx(
      props.disabled && b.disabled(),
      props.error && b.error(),
      props.focused && b.focused(),
      props.warning && b.warning(),
      className,
    );
  }, [props.disabled, props.error, props.focused, props.theme, props.warning]);
</formcontrolstate>