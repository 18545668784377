import {
  FlattenInterpolation,
  ThemedStyledProps,
  css,
} from 'styled-components';
import {
  Theme,
  assertTheme,
  interpolateFont,
  vr,
  vrCustomProperty,
} from '@mint/core';
import { TypographyProps } from './typography-props';

export const interpolateFontStyles = (
  props: TypographyProps,
): FlattenInterpolation<themedstyledprops<{ theme:="" Theme="" },="" any="">> => {
  const theme = assertTheme(props);
  const { variant = 'body' } = props;
  return css`
    ${interpolateFont(variant)}
    margin-bottom: 0;
    margin-block-start: 0;
    margin-block-end: 0;

    * + & {
      margin-top: ${vr(theme, variant)};
      margin-top: ${vrCustomProperty(theme, variant)};
    }

    .${`${theme.name}-typography--overline`} + & {
      margin-top: ${vr(theme, variant)};
      margin-top: ${vrCustomProperty(theme, variant)};
    }
  `;
};
</themedstyledprops<{>